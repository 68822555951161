import Vue from 'vue';



// Login component
import Login from 'etap/components/Login.vue';
import ResetPassword from "etap/components/ResetPassword.vue";
import SignUpForm from "etap/components/SignUpForm.vue";
import PasswordWithRequirements from "etap/components/FormWidgets/PasswordWithRequirements.vue";
import VerifyEmailNotification from "etap/components/authentication/VerifyEmailNotification.vue";

Vue.component('login-form', Login);
Vue.component('reset-password-form', ResetPassword);
Vue.component('sign-up-form', SignUpForm);
Vue.component('password-with-requirements', PasswordWithRequirements);
Vue.component('verify-email-notification', VerifyEmailNotification);

import EnergyDashboard from 'etap/components/Dashboard/EnergyDashboard.vue';
Vue.component('energy-dashboard', EnergyDashboard);

// Global Component Imports;
import CollapseList from 'etap/components/CollapseList.vue';
import Modal from 'etap/components/Modal.vue';
import ProjectDateField from 'etap/components/ProjectDateField.vue';
import PreviewPdf from 'etap/components/ReportComponents/PreviewPdf.vue';
import GlobalReportGenerator from 'etap/components/ReportComponents/GlobalReportGenerator.vue';
import HeaderDropMenu from 'etap/components/HeaderDropMenu.vue';
import AdobeSignRequest from 'etap/components/ReportComponents/AdobeSignRequest.vue';
import InputMask from 'etap/components/FormWidgets/InputMask.vue';
import FormBase64WYSIWYG from 'etap/components/FormWidgets/FormBase64WYSIWYG.vue';
import SubFormField from "etap/components/FormComponents/SubFormField.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ExpansionPanel from "etap/components/ExpansionPanel.vue";
import GoogleMapLink from "etap/components/FormComponents/GoogleMapLink.vue";
import FormSection from "etap/components/FormComponents/FormSection.vue";
import FormSkeleton from "etap/components/FormWidgets/FormSkeleton.vue";
import TableSkeleton from "etap/components/FormWidgets/TableSkeleton.vue";
import RightPanel from "etap/components/RightPanel.vue";
import Teleport from "etap/components/Teleport.vue";

Vue.component('collapse-list', CollapseList);
Vue.component('modal', Modal);
Vue.component('project-date-field', ProjectDateField);
Vue.component('preview-pdf', PreviewPdf);
Vue.component('global-report-generator', GlobalReportGenerator);
Vue.component('header-drop-menu', HeaderDropMenu);
Vue.component('adobe-sign-request', AdobeSignRequest);
Vue.component('input-mask', InputMask);
Vue.component('base64-wysiwyg', FormBase64WYSIWYG);
Vue.component('sub-form-field', SubFormField);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('expansion-panel', ExpansionPanel);
Vue.component('google-map-link', GoogleMapLink);
Vue.component('form-section', FormSection);
Vue.component('form-skeleton', FormSkeleton);
Vue.component('table-skeleton', TableSkeleton);
Vue.component('right-panel', RightPanel);
Vue.component('Teleport', Teleport);

/**
 * Require tabs components
 */
import Tabs from 'etap/components/Tabs/Tabs.vue';
import Tab from 'etap/components/Tabs/Tab.vue';
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

/**
 * File upload
 */
import FileUploaderContainer from 'etap/components/FileUpload/FileUploaderContainer.vue';
import Files from 'etap/components/FileUpload/Files.vue';
import UploadContainer from 'etap/components/FileUpload/UploadContainer.vue';

Vue.component('file-upload-container', FileUploaderContainer);
Vue.component('files', Files);
Vue.component('upload-container', UploadContainer);

import UnsubscribeEmail from 'etap/components/Emails/UnsubscribeEmail.vue';
import SendNotification from 'etap/components/Emails/SendNotification.vue';

Vue.component('send-notification', SendNotification);
Vue.component('unsubscribe-email', UnsubscribeEmail);

/**
 * Home Page components
 */
import Home from 'etap/components/Home/Home.vue';
import Search from 'etap/components/Home/Search.vue';
import ProjectStats from 'etap/components/Home/ProjectStats.vue';
import HomeProjectGridRow from 'app/components/GridRows/HomeProjectGridRow.vue';
import CreateProjectModal from 'etap/components/Home/CreateProjectModal.vue';
import GridDivRowPinned from 'etap/components/GridViews/Grid-DivRowPinned.vue';
import BannerNotificationContainer from 'etap/components/BannerNotifications/BannerNotificationContainer.vue';
import UpdateProjectStatusActionTypeHandler from 'etap/components/Home/UpdateProjectStatusActionTypeHandler.vue';

Vue.component('home', Home);
Vue.component('search', Search);
Vue.component('project-stats', ProjectStats);
Vue.component('home-project-grid-row', HomeProjectGridRow);
Vue.component('create-project-modal', CreateProjectModal);
Vue.component('div-row-pinned', GridDivRowPinned);
Vue.component('banner-notification-container', BannerNotificationContainer);
Vue.component('update-project-status-action-type-handler', UpdateProjectStatusActionTypeHandler);


/**
 * Settings Page components
 */
import EquipmentLibraryContainer from 'etap/components/EquipmentLibrary/layout/EquipmentLibraryContainer.vue';
import EquipmentLibraryRightContainer from 'etap/components/EquipmentLibrary/layout/EquipmentLibraryRightContainer.vue';
import EquipmentLibraryMeasureGroupsContainer from 'etap/components/EquipmentLibrary/layout/MeasureGroupsContainer.vue';
import EquipmentLibrarySearch from 'etap/components/EquipmentLibrary/EquipmentLibrarySearch.vue';
import EquipmentLibraryMeasureList from 'etap/components/EquipmentLibrary/layout/EquipmentLibraryMeasureList.vue';
import EquipmentLibraryMeasureEquipment from 'etap/components/EquipmentLibrary/EquipmentLibraryMeasureEquipment.vue';
import EquipmentForm from 'etap/components/EquipmentLibrary/layout/EquipmentForm.vue';
import ImportEquipment from 'etap/components/EquipmentLibrary/layout/ImportEquipment.vue';
import TwoFactorAuthentication
    from "etap/components/authentication/TwoFactorAuthentication.vue";
import ConfirmPasswordActionModal
    from "etap/components/ConfirmPasswordActionModal.vue";
import QplLookup
    from "etap/components/EquipmentLibrary/layout/QplLookup.vue";
import EquipmentLibraryItems
    from "etap/components/EquipmentLibrary/layout/EquipmentLibraryItems.vue";
import EquipmentLibraryBulkImport
    from "etap/components/EquipmentLibrary/layout/EquipmentLibraryBulkImport.vue";
import EquipmentItemRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentItemRow.vue";
import EquipmentLibraryMeasureRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentLibraryMeasureRow.vue"
import QplResultRow
    from "etap/components/EquipmentLibrary/GridRows/QplResultRow.vue";
import EquipmentBulkImportResultRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentBulkImportResultRow.vue";
import EquipmentBulkImportOperationLogRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentBulkImportOperationLogRow.vue";

Vue.component('equipment-library-container', EquipmentLibraryContainer);
Vue.component('equipment-library-right-container', EquipmentLibraryRightContainer);
Vue.component('equipment-library-measure-groups-container', EquipmentLibraryMeasureGroupsContainer);
Vue.component('equipment-library-search', EquipmentLibrarySearch);
Vue.component('equipment-library-measure-list', EquipmentLibraryMeasureList);
Vue.component('equipment-library-measure-equipment', EquipmentLibraryMeasureEquipment);
Vue.component('equipment-form', EquipmentForm);
Vue.component('import-equipment', ImportEquipment);
Vue.component('two-factor-authentication', TwoFactorAuthentication);
Vue.component('confirm-password-action', ConfirmPasswordActionModal);
Vue.component('qpl-lookup', QplLookup);
Vue.component('equipment-library-items', EquipmentLibraryItems);
Vue.component('equipment-library-bulk-import', EquipmentLibraryBulkImport);
Vue.component('equipment-item-row', EquipmentItemRow);
Vue.component('equipment-library-measure-row', EquipmentLibraryMeasureRow);
Vue.component('qpl-result-row', QplResultRow);
Vue.component('equipment-bulk-import-result-row', EquipmentBulkImportResultRow);
Vue.component('equipment-bulk-import-operation-log-row', EquipmentBulkImportOperationLogRow);

/**
 * Universal Page Vue Components boostrap
 */
import ReAssignProject from 'etap/components/Universal/ReAssignProject.vue';
import UniversalPageSelector from 'etap/components/Universal/UniversalPageSelector.vue';
import ProjectPaymentsContainer from 'app/components/Universal/ProjectPaymentsContainer.vue';

Vue.component('re-assign-project', ReAssignProject);
Vue.component('universal-page-selector', UniversalPageSelector);
Vue.component('project-payments', ProjectPaymentsContainer);


/**
 * Customer Information Components
 */
import CustomerInformationForm from 'etap/components/Forms/CustomerInformationForm.vue';
import FacilityBillAccountsForm from 'etap/components/FormWidgets/FacilityBillAccountsForm.vue';
import BillAccountsContainer from 'app/components/BillAccounts/BillAccountsContainer.vue';
import BillAccountLookup from 'etap/components/BillAccounts/BillAccountLookup.vue';
import BillAccountForm from 'app/components/BillAccounts/BillAccountForm.vue';
import BillAccount from 'etap/components/BillAccounts/BillAccount.vue';

Vue.component('customer-information-form', CustomerInformationForm);
Vue.component('facility-bill-accounts-form', FacilityBillAccountsForm);
Vue.component('bill-accounts-container', BillAccountsContainer);
Vue.component('bill-account-lookup', BillAccountLookup);
Vue.component('bill-account-form', BillAccountForm);
Vue.component('bill-account', BillAccount);


/**
 * Eligibility Check
 */

import EligibilityContainer from 'app/components/Eligibility/EligibilityContainer.vue';
import EligibilityForm from 'app/components/Eligibility/EligibilityForm.vue';
import EligibilityList from 'app/components/Eligibility/EligibilityList.vue';
import EligibleBillAccountRow from "../components/Eligibility/EligibleBillAccountRow.vue";

Vue.component('eligibility-container', EligibilityContainer);
Vue.component('eligibility-form', EligibilityForm);
Vue.component('eligibility-list', EligibilityList);
Vue.component('eligible-bill-account-row', EligibleBillAccountRow);



/**
 * Measure Picker Components
 */

import MeasurePickerContainer from 'etap/components/MeasurePicker/MeasurePickerContainer.vue';
import ZeroInformationWarning from 'etap/components/MeasurePicker/ZeroInformationWarning.vue';
import ScenarioList from 'etap/components/MeasurePicker/ScenarioList.vue';
import MeasureList from 'etap/components/MeasurePicker/MeasureList.vue';
import MeasureTabAccordion from 'etap/components/MeasurePicker/Accordion/MeasureTabAccordion.vue';
import MeasureTabAccordionItem from 'etap/components/MeasurePicker/Accordion/MeasureTabAccordionItem.vue';
import MeasureSelectionItem from 'etap/components/MeasurePicker/Accordion/MeasureSelectionItem.vue';
import AddScenarioModal from 'etap/components/MeasurePicker/Modals/AddScenarioModal.vue';
import ProjectMeasureContainer from 'etap/components/MeasurePicker/ProjectMeasureContainer.vue';
import MeasureSummary from 'etap/components/MeasurePicker/MeasureSummary.vue';
import RoomList from 'etap/components/MeasurePicker/RoomList.vue';
import Room from 'etap/components/MeasurePicker/Room.vue';
import ProjectMeasure from 'app/components/MeasurePicker/ProjectMeasure.vue';
import ProjectMeasureDetails from 'etap/components/MeasurePicker/ProjectMeasureDetails.vue';

import FBTechnology from 'etap/components/MeasurePicker/FormBuilderWidgets/Technology.vue';
import FBEquipment from 'etap/components/MeasurePicker/FormBuilderWidgets/Equipment.vue';
import FBFormCompletion from 'etap/components/MeasurePicker/FormBuilderWidgets/FormCompletion.vue';


import ThermostatAdjustment from 'app/components/FormComponents/ThermostatAdjustment/ThermostatAdjustment.vue';
import ThermostatAdjustmentForm from 'app/components/FormComponents/ThermostatAdjustment/ThermostatAdjustmentForm.vue';

import EligibilityRequirements from "app/components/FormComponents/EligibilityRequirements.vue";
import MeasurePickerEquipmentLibraryContainer from "etap/components/MeasurePicker/MeasurePickerEquipmentLibrary/MeasurePickerEquipmentLibraryContainer.vue";

Vue.component('measure-picker-container', MeasurePickerContainer);
Vue.component('zero-information-warning', ZeroInformationWarning);
Vue.component('scenario-list', ScenarioList);
Vue.component('measure-list', MeasureList);
Vue.component('tab-accordion', MeasureTabAccordion);
Vue.component('accordion-item', MeasureTabAccordionItem);
Vue.component('measure-selection-item', MeasureSelectionItem);
Vue.component('add-scenario-modal', AddScenarioModal);
Vue.component('project-measure-container', ProjectMeasureContainer);
Vue.component('measure-summary', MeasureSummary);
Vue.component('room-list', RoomList);
Vue.component('room', Room);
Vue.component('project-measure', ProjectMeasure);
Vue.component('project-measure-details', ProjectMeasureDetails);

Vue.component('equipment', FBEquipment);
Vue.component('form-completion', FBFormCompletion);

Vue.component('technology', FBTechnology);

Vue.component('thermostat-adjustment', ThermostatAdjustment);
Vue.component('thermostat-adjustment-form', ThermostatAdjustmentForm);

Vue.component('eligibility-requirements', EligibilityRequirements);
Vue.component('measure-picker-equipment-library-container', MeasurePickerEquipmentLibraryContainer)


/**
 * Report Page Components
 */
import GenerateAssessmentReport from 'etap/components/ReportComponents/GenerateAssessmentReport.vue';
import QCWidget from 'etap/components/ReportComponents/QCWidget.vue';
import SelectedScenarioSelector from 'etap/components/ReportComponents/SelectedScenarioSelector.vue';
import ProjectChecklist from 'etap/components/Checklist/ProjectChecklist.vue';
import ChecklistItem from 'etap/components/Checklist/ChecklistItem.vue';
import SubmitPhaseForCompletion from 'etap/components/ReportComponents/SubmitPhaseForCompletion.vue';
import UpgradeProjectProgramYear from 'etap/components/ReportComponents/UpgradeProjectProgramYear.vue';
import GenerateInvoices from 'etap/components/ReportComponents/GenerateInvoices.vue';
import GenerateMeasureListReport from 'etap/components/ReportComponents/GenerateMeasureList.vue';
import GenerateFinalSelectionForm from 'app/components/ReportComponents/GenerateFinalSelectionForm.vue';
import ProjectNeedsConfigurationUpdateBanner from "etap/components/ReportComponents/ProjectNeedsConfigurationUpdateBanner.vue";

Vue.component('generate-measure-list', GenerateMeasureListReport);
Vue.component('generate-assessment-report', GenerateAssessmentReport);
Vue.component('qc-widget', QCWidget);
Vue.component('selected-scenario-selector', SelectedScenarioSelector);
Vue.component('project-checklist', ProjectChecklist);
Vue.component('checklist-item', ChecklistItem);
Vue.component('submit-phase-for-completion', SubmitPhaseForCompletion);
Vue.component('upgrade-project-program-year', UpgradeProjectProgramYear);
Vue.component('generate-invoices', GenerateInvoices);
Vue.component('generate-final-selection-form', GenerateFinalSelectionForm);
Vue.component('project-needs-configuration-update-banner', ProjectNeedsConfigurationUpdateBanner);


/**
 * QC Page Components
 */
import QCCheckbox from 'etap/components/QC/QCCheckbox.vue';
import QCCheckboxList from 'etap/components/QC/QCCheckboxList.vue';
import QCRollupContainer from 'etap/components/QC/QCRollup/QCRollupContainer.vue';
import GroupedProjectMeasure from 'etap/components/QC/QCRollup/GroupedProjectMeasure.vue';
import RewindProject from 'etap/components/QC/RewindProject.vue';
import ZipFilesCreator from 'etap/components/ProjectZipFiles/ZipFilesCreator.vue';
import QCInspectionManager from 'app/components/Inspections/QCInspectionManager.vue';
import ProjectApprovalForm from 'etap/components/Forms/ProjectApprovalForm.vue';
import BillAccountsQC from 'etap/components/QC/BillAccountsQC.vue';
import BillAccountQCRow from 'etap/components/GridRows/BillAccountQCRow.vue';
import ProjectStatusUpdater from 'etap/components/QC/ProjectStatusUpdater.vue';
import RecalculateProjectCalculations from 'etap/components/Universal/RecalculateProjectCalculations.vue';
import JobStatuses from 'etap/components/Universal/JobStatuses.vue'
import JobStatusRow from 'etap/components/GridRows/JobStatusRow.vue';

import DropdownFlag from "etap/components/FormComponents/DropdownFlag.vue";
import AtRiskCheckbox from "app/components/FormComponents/AtRiskCheckbox.vue";

Vue.component('qc-checkbox', QCCheckbox);
Vue.component('qc-checkbox-list', QCCheckboxList);
Vue.component('qc-rollup-container', QCRollupContainer);
Vue.component('grouped-project-measure', GroupedProjectMeasure);
Vue.component('rewind-project', RewindProject);
Vue.component('zip-files-creator', ZipFilesCreator);
Vue.component('qc-inspection-manager', QCInspectionManager);
Vue.component('bill-accounts-qc', BillAccountsQC);
Vue.component('bill-account-qc-row', BillAccountQCRow);
Vue.component('project-approval-form', ProjectApprovalForm);
Vue.component('project-status-updater', ProjectStatusUpdater);
Vue.component('recalculate-project-calculations', RecalculateProjectCalculations);
Vue.component('project-job-statuses', JobStatuses);
Vue.component('job-status-row', JobStatusRow);

Vue.component('dropdown-flag', DropdownFlag);
Vue.component('at-risk-checkbox', AtRiskCheckbox);

/**
 * Inspector Page
 */
import InspectionForm from 'etap/components/Inspections/Form.vue';

Vue.component('inspection-form', InspectionForm);

/**
 * Schedule builder
 */
import ScheduleBuilder from 'etap/components/ScheduleBuilder/ScheduleBuilder.vue';
import ScheduleSelector from 'etap/components/ScheduleBuilder/ScheduleSelector.vue';
import ScheduleBuilderForm from 'etap/components/ScheduleBuilder/ScheduleBuilderForm.vue';

Vue.component('schedule-builder', ScheduleBuilder);
Vue.component('schedule-selector', ScheduleSelector);
Vue.component('schedule-builder-form', ScheduleBuilderForm);

/**
 * Leads Components
 */
import LeadForm from 'etap/components/Leads/Form.vue';
import ProjectLeadRow from 'etap/components/GridRows/ProjectLeadRow.vue';

Vue.component('lead-form', LeadForm);
Vue.component('project-lead-row', ProjectLeadRow);

/**
 * Payement Authorization
 */
 import ExistingCompanyAndAddressWidget from 'etap/components/PaymentAuthorization/ExistingCompanyAndAddressWidget.vue';

 Vue.component('existing-company-and-address-widget', ExistingCompanyAndAddressWidget);

/**
 * Project Components
 */
import BypassProjectSubmissionValidation from 'etap/components/Project/BypassProjectSubmissionValidation.vue';

Vue.component('bypass-project-submission-validation', BypassProjectSubmissionValidation);
