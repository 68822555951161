<template>
    <div class="payments-container">
        <div v-if="loadingPayments === false">
            <table v-if="payments.length > 0">
                <tr>
                    <th>Batch Date</th>
                    <th>Batch Identifier</th>
                    <th>Check Mailed Date</th>
                    <th>Check #</th>
                    <td></td>
                </tr>
                <tr v-for="payment in payments" :key="payment.id">
                    <td>{{ payment.nexant_batch_date }}</td>
                    <td>{{ payment.nexant_batch_identifier }}</td>
                    <td>{{ payment.check_mailed_date }}</td>
                    <td>{{ payment.check_number }}</td>
                    <td>
                        <span @click="editPaymentForm(payment)" class="fa fa-edit"></span>
                        <span @click="deletePayment({ payment, project})" class="fa fa-times"></span>
                    </td>
                </tr>
            </table>
            <div v-else class="empty-message">
                There are currently no payments for this project
            </div>
        </div>
        <div v-else class="loading-icon">
            <i aria-label="Loading" class=" fa fa-spinner fa-spin fa-2x"></i>
        </div>
        <div v-if="canAddPayment">
            <button @click="createPaymentForm" class="inputbutton1">Add Payment</button>
        </div>
        <modal
            v-if="showFormModal"
            modalWidth="600px"
            @close="showFormModal = false">

            <span slot="header"><span v-if="formType !== ''">{{ formType }}</span> Project Payment</span>

            <slot slot="body">
                <vue-form
                    :form-config="paymentsFormConfiguration"
                    :form-data="currentPayment"
                    :api-client="$api"
                    :form-submit-url="'/api/projects/' + project.id + '/payments'"
                    @cancel-form="closeForm"
                    @close-form="closeForm"
                    @created="payment => savePayment('add', payment)"
                    @updated="payment => savePayment('update', payment)"
                    :close-on-save="false"
                ></vue-form>
            </slot>
        </modal>
    </div>
</template>
<script>
    import { mapState, mapActions, mapMutations } from 'vuex';
    export default {


        props: {
            project: {
                required: true,
                type: Object,
            },
            paymentsFormConfiguration: {
                required: true,
                type: Object,
            },
            maxPayments: {
                type: Number,
                default() {
                    return 0;
                }
            }
        },

        data() {
            return {
                loadingPayments: false,
                showFormModal: false,
                formType: '',
                currentPayment: {},
            }
        },

        created() {
            this.getPayments();
        },

        computed: {
            ...mapState('project_payments', [
                'payments'
            ]),
            canAddPayment() {
                if(this.maxPayments === 0 || this.payments.length < this.maxPayments) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapActions('project_payments', [
                'getPaymentsForProject',
                'deletePayment'
            ]),
            ...mapMutations('project_payments', [
                'addPayment',
                'updatePayment'
            ]),
            getPayments() {
                this.loadingPayments = true;
                this.getPaymentsForProject(this.project.id).then(payments => {
                    this.loadingPayments = false;
                }).catch(error => {
                    this.loadingPayments = false;
                })
            },
            createPaymentForm() {
                this.formType = 'Create';

                this.currentPayment = {
                    project_id: this.project.id,
                    user_id: window.MAT.user.id
                };

                this.showFormModal = true;
            },
            editPaymentForm(payment) {
                this.formType = 'Edit';

                this.currentPayment = payment;
                this.showFormModal = true;
            },
            closeForm() {
                this.showFormModal = false;
            },
            savePayment(type, payment) {
                switch(type) {
                    case 'add':
                        this.addPayment(payment);
                        break;
                    case 'update':
                        this.updatePayment(payment);
                        break;
                }
                this.showFormModal = false;
                this.currentPayment = {};
                this.getPayments();
            },
        }

    }
</script>
